<template>
<div v-if="rec">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Organizations" to="/organizations/list/1" />

      <q-breadcrumbs-el v-if="$route.name === 'orgStateView'" label="States" to="/organizations/state" />
      <q-breadcrumbs-el v-if="$route.name === 'orgStateView'" :label="siteArrays.states[$route.params.stateCode]" :to="{name: 'orgStateList', params: { stateCode: $route.params.stateCode, pageId: 1}}" />

      <q-breadcrumbs-el v-if="$route.name === 'orgCountryView'" label="Countries" to="/organizations/country" />
      <q-breadcrumbs-el v-if="$route.name === 'orgCountryView'" :label="siteArrays.countries[$route.params.countryCode]" :to="{name: 'orgCountryList', params: { countryCode: $route.params.countryCode, pageId: 1}}" />
      <q-breadcrumbs-el :label="$route.params.recId" />

      <q-breadcrumbs-el>
        View
        &nbsp;

        <q-btn v-if="hasRecId($route.params.orgId)" flat color="red" size="xs" icon="fas fa-pencil" @click="$router.push({name: 'orgEdit', params: {orgId: $route.params.orgId}})"></q-btn>
      </q-breadcrumbs-el>


    </q-breadcrumbs>

    <div class="q-mt-lg text-h5">
      {{rec.name}}
    </div>
    <div class="q-mb-sm text-weight-medium text-grey-8">
      <span>Created {{ rec.created_on | date }}</span>
      &middot;
      <span v-if="rec.location">
        <q-icon name="place" />
        {{rec.location.city}},
        <span v-show="rec.location.state_code">
          <router-link class="text-primary" :to="{name: 'orgStateList', params: { pageId: 1, stateCode: rec.location.state_code }}">
            {{rec.location.state_code}},
          </router-link>
        </span>
          <router-link class="text-primary" :to="{name: 'orgCountryList', params: { pageId: 1, countryCode: rec.location.country_code }}">
           {{siteArrays.countries[ rec.location.country_code ]}}
          </router-link>
      </span>
    </div>

    <div class="q-mb-md" style="white-space: pre-line">
      {{rec.mission || 'No organization description found'}}
    </div>


    <div>
      <h6 class="q-my-sm">Current Posts</h6>
      <table v-if="orgPosts && orgPosts.length > 0" class="table table-sm table-striped full-width">
        <job-list-item v-for="post in orgPosts" :key="post._id" :post="post"></job-list-item>
      </table>
      <div v-else>
        No posts found.
      </div>
    </div>

     <div class="row">
      <div class="col-12 q-px-sm">
        <show-data :data="rec"></show-data>
      </div>
    </div>

</div>
</template>

<script>
import ApiService from "src/services/api.service"
import orgModule from "../../store";
import storeRecViewMixin from "src/mixins/store_rec_view.mixin";
import { mapState, mapGetters } from 'vuex'

export default {
  mixins: [
    storeRecViewMixin,
  ],
  data() {
    return {
      orgPosts: [],
      bookmarkSaving: false,

      collection: 'organizations',
      vuexCollection: 'organization.recs',
      vuexPath: 'organization',
      vuexPageRequestAction: orgModule.actionTypes.LoadRecordsPageRequest,
      vuexGetRecordAction: orgModule.actionTypes.GetRecordRequest,

    };
  },
  computed: {
    ...mapState('organization', {
        loading: 'loading',
      }),
    ...mapState('site', {
        siteArrays: 'arrs',
      }),
    ...mapGetters('organization', [
      'hasRecId',
    ]),
    // orgPosts() {
    //   // return this.$store.getters['post/getOrgPosts'](this.$route.params.orgId);
    //   return [];
    // }
  },
  async created () {
    // this.reqData();

    // // GET org posts...
    try {
      const apiData = await ApiService.get('/api/posts/organization/' + this.$route.params.recId + '/1');
      this.orgPosts = apiData.data.recs;
      return true;
    } catch (error) {
      console.log("caught error in load org post records", error.response);
      throw error;
    }

  },
  methods: {
    // reqData (force) {
    //   if (! this.org || force) {
    //     this.$store.dispatch( 'org/' + OrgActionTypes.OrgGetRecordRequest, { recId: this.$route.params.orgId } );
    //   }
    // },
  },
}
</script>
